<template>
  <div class="login">
    <div class="well-header">
      <span class="brand"
        ><img v-if="getLogoURL" id="brandlogo" alt="brand" :src="getLogoURL"
      /></span>
    </div>
    <form data-testid="login-form" @submit.prevent="submit">
      <div id="password">
        <span class="login-icon main-color"
          ><font-awesome-icon icon="key"
        /></span>
        <input
          data-testid="password-input"
          type="password"
          name="password"
          v-model="password"
          placeholder="Password"
        />
      </div>
      <b-alert
        id="login-error"
        v-if="loginError"
        data-testid="login-error-alert"
        show
        variant="danger"
        >{{ loginError }}</b-alert
      >
      <b-button
        class="success-color"
        data-testid="login-submit"
        type="submit"
        size="lg"
        >Login</b-button
      >
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { login } from '@/api/link';

export default {
  name: 'Login',
  props: {
    onSuccess: Function,
  },
  data() {
    return {
      password: '',
      loginError: '',
    };
  },
  methods: {
    submit() {
      login(this.$route.params.uid, this.password)
        .then((response) => {
          this.needsLogin = false;
          this.$cookies.set('X-Link-Auth', response.data);
          const body = document.getElementById('container');
          if (body) {
            const [backgroundAttr] = this.getLoginBackground;
            if (backgroundAttr) {
              body.style[backgroundAttr] = '';
              body.style.backgroundRepeat = '';
              body.style.backgroundPosition = '';
              body.style.backgroundAttachment = '';
              body.style.backgroundSize = '';
            }
          }
          this.$emit('onSuccess', response.data);
        })
        .catch((error) => {
          this.loginError = error.response.data.error;
        });
    },
  },
  computed: {
    ...mapGetters({
      getLoginBackground: 'BrandStore/getLoginBackground',
      getLogoURL: 'BrandStore/getLogoURL',
    }),
  },
  created() {
    const body = document.getElementById('container');
    if (body) {
      const [backgroundAttr, backgroundImage] = this.getLoginBackground;
      if (backgroundAttr) {
        body.style[backgroundAttr] = backgroundImage;
        body.style.backgroundRepeat = 'no-repeat';
        body.style.backgroundPosition = 'center center';
        body.style.backgroundAttachment = 'fixed';
        body.style.backgroundSize = 'cover';
      }
    }
  },
};
</script>
<style>
.login {
  background: #fafafa;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
  margin: 5% auto 10px;
  padding: 0;
  width: 320px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-icon {
  border-style: solid;
  border-width: 1px;
  border-radius: 3px 0 0 3px;
  zoom: 1;
  height: 18px;
  padding: 10px;
  width: 10px;
  box-sizing: content-box;
}

.login-icon svg {
  vertical-align: initial;
  color: white;
}

.login input[type="text"],
.login input[type="password"],
.login select {
  border-radius: 0 5px 5px 0;
  height: 40px;
  line-height: 20px;
  padding: 0 5px;
  vertical-align: top;
  width: 215px;
  margin: 0;
  border-color: #ddd;
  border: 1px solid #ccc;
}
.well-header {
  border-style: solid;
  border-width: 0 0 1px 0;
  background: none repeat scroll 0 0 #ffffff;
  border-radius: 4px 4px 0 0;
  box-shadow: none;
  margin-bottom: 20px;
  padding: 10px;
  border-color: #ddd;
}
.login #password {
  display: flex;
  justify-content: center;
}
.login button {
  margin: 15px 0 30px;
  width: 80%;
}
#login-error {
  width: 80%;
  margin: 24px auto auto auto;
  font-weight: 400;
  font-size: 14px;
}
</style>
