<template>
  <ListItem
    :title="file.name"
    :path="getFilePath(file)"
    :secondaryInfo="secondaryInfo"
    :icon="getFileIcon(file)"
    :titleButtons="getFileButtons(file)"
    :selectItem="selectListItem"
    :id="file.id"
    :selected="file.selected"
    :buttons="getActionButtons(file)"
    :hasPreview="file.isfile"
    @onPreview="preview"
  />
</template>

<script>
import ListItem from '@/components/ListItem.vue';
import { bytesToPretty, formatDate } from '@/utils/utils';

export default {
  components: {
    ListItem,
  },
  name: 'FileListItem',
  props: {
    file: Object,
    basePath: String,
    acl: Object,
    selectListItem: Function,
    getFilePath: Function,
    getFileButtons: Function,
    getFileIcon: Function,
    getActionButtons: Function,
    onPreview: Function,
  },
  computed: {
    secondaryInfo() {
      const beginningInfo = formatDate(this.file.time, 'M/D/Y');
      if (this.file.isdir) {
        return `${beginningInfo} |  ${this.file.items} items`;
      }
      return `${beginningInfo} |  ${bytesToPretty(this.file.size)}`;
    },
  },
  methods: {
    preview() {
      this.$emit('onPreview', this.file);
    },
  },
};
</script>
