<template>
  <div>
    <b-table :fields="fields" :items="items" primary-key="id">
      <template #cell(name)="data">
        <div class="cell-filename" v-on:click="selectListItem(data.value.id)">
          <font-awesome-icon
            class="file-check"
            v-if="data.value.selected"
            icon="check-square"
          />
          <font-awesome-icon
            class="file-check"
            v-else
            :icon="['far', 'square']"
          />
          <div class="file-icon">
            <img data-testid="file-icon-img" :src="getFileIcon(data.value)" />
          </div>
          <div class="file-details">
            <div class="file-name">
              <a
                :id="data.item.id"
                data-testid="item-name-a-tag"
                href="javascript:void(0)"
                @click.stop="preview"
                v-if="data.item.isfile"
                >
                {{data.value.name}}
              </a>
              <a
                data-testid="item-name-a-tag"
                :href="getFilePath(data.item)"
                @click.stop="onClick"
                v-else
              >
                {{data.value.name}}
              </a>
            </div>
            <div
              data-testid="title-buttons"
              v-for="button in getFileButtons(data.value)"
              :key="button.title"
              class="file-btn"
            >
              <a
                data-toggle="tooltip"
                :href="button.href"
                class="btn-mini btn-file-action hidden-phone"
                @click.stop="onClick"
              >
                <font-awesome-icon v-if="button.icon" :icon="button.icon" />
              </a>
            </div>
          </div>
        </div>
      </template>
      <template #cell(actions)="data">
        <ButtonDropdown :buttons="getActionButtons(data.item)" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { bytesToPretty, formatDate } from '@/utils/utils';
import ButtonDropdown from '@/components/core/ButtonDropdown.vue';

export default {
  name: 'FileTable',
  components: {
    ButtonDropdown,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'File Name',
          formatter: (value, key, item) => item,
        },
        {
          key: 'time',
          label: 'Date',
          formatter: (value) => formatDate(value, 'M/D/Y'),
        },
        {
          key: 'info',
          label: 'Info',
          formatter: 'getInfo',
        },
        {
          key: 'actions',
          label: '',
          formatter: (value, key, item) => item,
        },
      ],
    };
  },
  props: {
    items: Array,
    basePath: String,
    acl: Object,
    selectListItem: Function,
    getFilePath: Function,
    getFileButtons: Function,
    getFileIcon: Function,
    getActionButtons: Function,
    onPreview: Function,
  },
  methods: {
    getInfo(value, key, item) {
      if (item.isdir) {
        return `${item.items} items`;
      }
      return bytesToPretty(item.size);
    },
    preview(e) {
      this.$emit('onPreview', this.items.find((item) => item.id === parseInt(e.target.id, 10)));
    },
    onClick() {
    },
  },
};
</script>
<style>
table thead {
  background-color: #fafafa;
  color: #777;
}
table thead:first-child div {
  text-align: left;
  padding-left: 70px;
}
table tr td:not(:first-child) {
  opacity: 0.75;
}
table tr td:last-child {
  opacity: 1;
}
table .cell-filename {
  display: flex;
  align-items: center;
  padding-inline-start: 40px;
}
table .file-check {
  margin: 0;
}
table .file-icon {
  height: 30px;
  left: 50px;
  margin: 2px 0;
  width: 40px;
  padding: 0 5px;
}
table .file-icon img {
  max-height: 20px;
}
table .file-btn {
  vertical-align: text-bottom;
  display: inline;
  margin-left: 4px;
}
table .file-details {
  display: flex;
  margin-left: 0;
}
table .btn-mini {
  height: 20px;
  line-height: 20px;
  color: #777;
  background-color: #eee;
  padding: 0px 6px;
}
table .btn-mini svg {
  height: 0.8em;
  width: 0.8em;
}
table .file-actions {
  margin: 0;
}
</style>
