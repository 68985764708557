<template>
  <div ref="viewer" id="wrapper"></div>
</template>

<script>
import WebViewer from '@pdftron/webviewer';

export default {
  name: 'SFWebViewer',
  methods: {
    loadfile(url, file) {
      let disabledElements = [
        'ribbons',
        'toggleNotesButton',
        'toolsHeader',
        'stickyToolButton',
        'highlightToolButton',
        'freeHandToolButton',
        'freeTextToolButton',
        'textHighlightToolButton',
        'textUnderlineToolButton',
        'textSquigglyToolButton',
        'textStrikeoutToolButton',
        'linkButton',
      ];
      if (file.acl.write) {
        disabledElements = [];
      }
      WebViewer(
        {
          path: `${process.env.BASE_URL}lib`,
          webviewerServerURL: `https://${window.location.hostname}/pdftron`,
          licenseKey:
            'Lumanox, LLC(lumanox.com):OEM:SmartFile::B+:AMS(20220630):'
            + 'E9554FAC04E7BE3FD7E8B830C3D897C8E6612FD5C9707A4DC50C6511C7DD10B64A84BEF5C7',
          disabledElements,
          disableLogs: true,
        },
        this.$refs.viewer
      ).then((instance) => {
        const { annotManager, Annotations } = instance;

        annotManager.addEventListener('annotationChanged', (annotations, action, options) => {
          if (options.imported && action === 'add') {
            const links = annotations.filter((a) => a instanceof Annotations.Link);
            annotManager.deleteAnnotations(links);
          }
        });
        const options = {
          customHeaders: {
            Cookie: `X-Link-Auth=${this.$cookies.get('X-Link-Auth')}`,
          },
        };
        instance.loadDocument(url, options, { extension: file.extension });
      });
    },
  },
};
</script>

<style scoped>
div {
  width: 100%;
  height: 75vh;
}
</style>
