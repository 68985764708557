import { getPreviewHeight, getPreviewWidth } from '@/utils/utils';
import { api, createPaginationParams } from './index';

const LINK_API = '/api/3/link';

export function login(uid, password) {
  return api.post(`${LINK_API}/login/${uid}/`, { password });
}

export function get(uid, route, page, limit, sort) {
  return api.get(
    `${LINK_API}/${uid}/${route}${createPaginationParams(page, limit, sort)}`,
  );
}

export function getPreviewURL(uid, route, filename, viewPref) {
  const previewWidth = getPreviewWidth(viewPref);
  const previewHeight = getPreviewHeight(viewPref);
  return `${LINK_API}/${uid}/${route}${encodeURIComponent(filename)}?download=true&preview=true&width=${previewWidth}&height=${previewHeight}`;
}

export function getDownloadURL(uid, route, filename) {
  return `${LINK_API}/${uid}/${route}${encodeURIComponent(filename)}?download=true`;
}

export function upload(uid, route, data, uploadCallback) {
  return api.post(`${LINK_API}/upload/${uid}/${route}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...uploadCallback,
  });
}
