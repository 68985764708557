<template>
  <BrandedModal
    data-testid="upload-modal"
    id="upload-modal"
    ref="upload-modal"
    title="Upload"
    :model="show"
  >
    <template #branded-modal-content>
      <b-form @submit="onSubmit">
        <div class="uploader-table hide-it">
          <table
            data-testid="testtable"
            class="table"
            v-if="form.files.length > 0"
          >
            <thead class="plupload-thead">
              <tr>
                <th class="upload-file-name">Filename</th>
                <th class="upload-file-status hidden-phone">Status</th>
                <th class="upload-file-size hidden-phone">Size</th>
                <th class="upload-file-action">Remove</th>
              </tr>
            </thead>
            <tbody id="filelist">
              <tr v-for="file in form.files" :key="file.name">
                <td>{{ file.$path || file.name }}</td>
                <td>
                  <b-progress
                    :style="progressBackgroundColor(file.name)"
                    show-progress
                    striped
                    :animated="animated(file.name, file.progress)"
                    max="100"
                    :value="file.progress"
                  ></b-progress>
                </td>
                <td>{{ formatSize(file.size) }}</td>
                <td>
                  <button
                    class="removeBtn"
                    @click="removeFile(file)"
                    data-testid="remove-file"
                    v-show="!uploading"
                  >
                    <font-awesome-icon id="removeFile" icon="times-circle" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div id="errors">
            <b-alert
              v-for="(error, name) in errors"
              :key="name"
              show
              variant="danger"
              data-testid="error-text"
            >
              Error uploading {{ name }}: {{ error }}
            </b-alert>
          </div>
          <b-form-file
            id="formFile"
            data-testid="form-file-upload"
            multiple
            directory
            no-traverse
            :placeholder="dragDropText"
            v-model="form.files"
            :file-name-formatter="formatNames"
            v-on:input="addProgress"
          >
          </b-form-file>

          <b-button
            data-testid="upload-modal-button"
            id="uploadBtn"
            type="submit"
            v-if="form.files.length > 0 && !uploading"
            class="success-color"
            >UPLOAD</b-button
          >
        </div>
      </b-form>
    </template>
    <template #branded-modal-footer>
      <b-button @click="closeModal">
        Cancel
      </b-button>
    </template>
  </BrandedModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { bytesToPretty } from '@/utils/utils';
import { upload } from '@/api/link';
import BrandedModal from '@/components/core/BrandedModal.vue';
import Vue from 'vue';
import PromisePool from '@supercharge/promise-pool';

export default {
  name: 'UploadModal',
  components: {
    BrandedModal,
  },
  data() {
    return {
      error: false,
      errors: {},
      uploading: false,
      show: false,
      completed: 0,
      form: {
        files: [],
      },
    };
  },
  props: {
    onUploadOnly: Function,
    hasListPermissions: Boolean,
  },
  mounted() {
    if (this !== undefined && this._data.show) {
      this.$refs['upload-modal'].$children[0].show();
    }
  },
  computed: {
    canUploadFolders() {
      const isChromium = window.chrome;
      const winNav = window.navigator;
      const vendorName = winNav.vendor;
      const isIOSChrome = winNav.userAgent.match('CriOS');
      let canUploadFolders = false;

      if (isIOSChrome) {
        canUploadFolders = true;
      } else if (
        isChromium !== null
        && isChromium !== undefined
        && vendorName === 'Google Inc.'
      ) {
        // is Google Chrome
        canUploadFolders = true;
      }
      return canUploadFolders;
    },
    dragDropText() {
      if (this.canUploadFolders) {
        return 'DRAG AND DROP FILES OR FOLDERS HERE\n -or-';
      }
      return 'DRAG AND DROP FILES HERE';
    },
    progressBackgroundColor() {
      return (filename) => {
        if (this.errors[filename] === undefined) {
          return `background-color:${this.getBackgroundColor('main')}`;
        }
        return 'background-color:#dc3545';
      };
    },
    animated() {
      return (filename, val) => {
        if (this.errors[filename] === undefined && val !== 100) {
          return true;
        }
        return false;
      };
    },
    ...mapGetters({
      getBackgroundColor: 'BrandStore/getBackgroundColor',
    }),
  },
  methods: {
    addProgress(files) {
      this.errors = {};
      files.forEach((element, index) => {
        Vue.set(this.form.files[index], 'progress', 0);
      });
    },
    formatSize(size) {
      return bytesToPretty(size);
    },
    formatNames() {
      return this.dragDropText;
    },
    removeFile(file) {
      this.form.files = this.form.files.filter((fileObj) => file !== fileObj);
    },
    uploadProgress(id) {
      return (progress) => {
        const file = this.form.files[id];
        if (file) {
          file.progress = Math.floor((progress.loaded * 100) / progress.total);
          Vue.set(this.form.files, id, file);
        }
      };
    },
    onSubmit(event) {
      event.preventDefault();
      this.uploading = true;
      this.error = false;
      const { files } = this.form;
      const failedFiles = [];
      this.completed = 0;
      const uploads = [];
      for (let i = 0; i < files.length; i++) {
        files[i].progress = '';
        const config = {
          onUploadProgress: this.uploadProgress(i).bind(this),
        };
        const route = this.$route.hash.replace('#/', '');
        uploads.push({
          uid: this.$route.params.uid,
          route,
          file: files[i],
          config,
        });
      }
      PromisePool.withConcurrency(5).for(uploads).process((u) => {
        const formData = new FormData();
        formData.append('file', u.file);
        if (u.file.$path) {
          formData.append('name', u.file.$path);
        }

        return upload(u.uid, u.route, formData, u.config)
          .then(() => {
            this.completed += 1;
            if (this.completed === files.length) {
              if (!this.error) {
                this.closeModal();
              }
              this.uploading = false;
              this.sendUploadedFiles(files, failedFiles);
            }
          })
          .catch((error) => {
            this.error = true;
            this.completed += 1;
            const filename = error.config.data.get('file').name;
            Vue.set(this.errors, filename, error.message);
            const index = files.findIndex((file) => file.name === filename);
            failedFiles.push(files[index]);
            if (this.completed === files.length) {
              this.uploading = false;
              if (!this.hasListPermissions) {
                this.closeModal();
                this.sendUploadedFiles(files, failedFiles);
              }
            }
          });
      });
    },
    sendUploadedFiles(files, failedFiles) {
      if (failedFiles.length === 0) {
        this.$emit('onUploadOnly', files, failedFiles);
      } else {
        const successfulFiles = files.filter((file) => !failedFiles.includes(file));
        this.$emit('onUploadOnly', successfulFiles, failedFiles);
      }
    },
    closeModal() {
      this.$bvModal.hide('upload-modal');
      this.form.files = [];
    },
  },
};
</script>

<style>
#upload-modal .modal-dialog {
  max-width: 95%;
}

#upload-modal .modal-footer button {
  font-weight: 700;
  background-color: #e9ecef;
  color: #495057;
}
#upload-modal form .uploader-table {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
#upload-modal table th {
  color: var(--main-color);
  opacity: 0.7;
}
table th:not(:first-child),
td:not(:first-child) {
  text-align: center;
}
#upload-modal table td {
  color: var(--main-color);
}
#upload-modal .removeBtn {
  color: #777;
  background-color: #eee;
  border: none;
}
#upload-modal button svg {
  margin: 0px 5px;
}
#upload-modal .b-form-file {
  margin-bottom: 50px;
}
#upload-modal .custom-file-label {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  overflow: visible;
}
#upload-modal .custom-file-label::after {
  position: unset;
  text-align: center;
  content: "Browse for Files";
  border: none;
  border-radius: 3px;
  font-weight: 700;
  margin-top: 20px;
}
#upload-modal .d-block {
  display: flex !important;
  flex-wrap: wrap;
  font-weight: 700;
}
#uploadBtn {
  margin-top: 20px;
  font-weight: 700;
}
.progress .progress-bar {
  background-color: inherit;
}
</style>
