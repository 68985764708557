<template>
  <div v-if="buttons" class="btn-group file-actions">
    <div v-if="buttons.length == 1 && getViewPref != 'th'">
      <a
        data-testid="action-buttons"
        v-for="button in buttons"
        :key="button.title"
        :href="button.href"
        v-on:click="button.onclick"
        class="btn"
      >
        <font-awesome-icon
          v-if="button.icon"
          class="visible-phone"
          :icon="button.icon"
        />
        <span class="hidden-phone">{{ button.title }}</span>
      </a>
    </div>

    <div v-else-if="buttons.length >= 1">
      <b-dropdown toggle-class="text-decoration-none" right size="sm">
        <b-dropdown-item-button
          v-for="button in buttons"
          :key="button.title"
          :href="button.href"
          v-on:click="button.onclick"
        >
          {{ button.title }}
        </b-dropdown-item-button>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonDropdown',
  props: {
    buttons: Array,
  },
  computed: {
    getViewPref() {
      return this.$store.getters['PreferenceStore/getPreference']('view');
    },
  },
};
</script>

<style scoped>
#btn-dropdown svg {
  margin: 0;
}
</style>
