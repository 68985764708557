<template>
  <BrandedModal
    data-testid="preview-modal"
    id="preview-modal"
    ref="preview-modal"
    :title="file.name"
  >
    <template #branded-modal-actions>
      <button
        @click="download()"
        class="header-btn"
        data-testid="download-button"
      >
        <font-awesome-icon icon="download" />
      </button>
      <button @click="print()" class="header-btn" data-testid="print-button">
        <font-awesome-icon icon="print" />
      </button>
    </template>
    <template #branded-modal-content>
      <SFWebViewer v-if="isPDFTronFile" ref="webviewer" data-testid="pdftron-viewer"/>
      <ThreeDViewer
        v-else-if="is3DFile"
        ref="three-viewer"
        data-testid="three-viewer"
      />
      <div v-else-if="isAudioFile">
        <audio :src="url" controls="true"></audio>
      </div>
      <div v-else-if="isVideoFile">
        <video
          data-testid="video-player"
          autoplay
          data-title="Autoplay"
          controls
        >
          <source :src="url" controls />
        </video>
      </div>
      <div v-else>
        <PreviewViewer ref="preview-viewer" data-testid="preview-viewer" />
      </div>
    </template>
    <template #branded-modal-footer>
      <div id="footer">
        <button @click="download()" class="footer-btn btn">
          Download
        </button>
        <button @click="cancel()" class="footer-btn btn">
          Cancel
        </button>
      </div>
    </template>
  </BrandedModal>
</template>

<script>
import {
  pdftronExtensions,
  audioExtensions,
  videoExtensions,
  imageExtensions,
  threeDExtensions,
} from '@/utils/consts';
import SFWebViewer from '@/components/modals/preview/SFWebViewer.vue';
import PreviewViewer from '@/components/modals/preview/PreviewViewer.vue';
import ThreeDViewer from '@/components/modals/preview/ThreeDViewer.vue';
import BrandedModal from '@/components/core/BrandedModal.vue';
import { getDownloadURL, getPreviewURL } from '@/api/link';

export default {
  name: 'PreviewModal',
  components: {
    SFWebViewer,
    PreviewViewer,
    ThreeDViewer,
    BrandedModal,
  },
  data() {
    return {
      file: '',
    };
  },
  computed: {
    isPDFTronFile() {
      return this.isInArray(pdftronExtensions, this.file.extension);
    },
    isAudioFile() {
      return this.isInArray(audioExtensions, this.file.extension);
    },
    isVideoFile() {
      return this.isInArray(videoExtensions, this.file.extension);
    },
    is3DFile() {
      return this.isInArray(threeDExtensions, this.file.extension);
    },
    isPreviewServerFile() {
      return (
        !this.isAudioFile
        && !this.isVideoFile
        && !this.isPDFTronFile
        && !this.is3DFile
      );
    },
    route() {
      return this.$route.hash.replace('#/', '');
    },
    url() {
      if (this.isPreviewServerFile) {
        return getPreviewURL(
          this.$route.params.uid,
          this.route,
          this.file.name,
          'preview'
        );
      }
      if (this.isPDFTronFile) {
        const host = process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_PDFTRON_URL
          : '$VUE_APP_PDFTRON_URL';
        return (
          host
          + getDownloadURL(this.$route.params.uid, this.route, this.file.name)
        );
      }
      return getDownloadURL(this.$route.params.uid, this.route, this.file.name);
    },
  },
  methods: {
    download() {
      window.open(
        getDownloadURL(this.$route.params.uid, this.route, this.file.name)
      );
      this.$refs['preview-modal'].$children[0].hide();
    },
    cancel() {
      this.$root.$emit('bv::hide::modal', 'preview-modal');
    },
    print() {
      let popup;

      function closePrint() {
        if (popup) {
          popup.close();
        }
      }
      const printUrl = getDownloadURL(
        this.$route.params.uid,
        this.route,
        this.file.name
      ).replace('download', 'ui');
      popup = window.open(printUrl);
      popup.onbeforeunload = closePrint;
      popup.onafterprint = closePrint;
      popup.focus(); // Required for IE
      popup.print();
      this.$refs['preview-modal'].$children[0].hide();
    },
    isInArray(array, type) {
      return (
        array.find((format) => format === type) !== undefined
      );
    },
    showModalWithFile(file) {
      this.file = null;
      this.file = file;
      this.file.isImage = this.isInArray(imageExtensions, file.extension);
      this.$refs['preview-modal'].$children[0].show();
    },
  },
  updated() {
    this.$nextTick(() => {
      if (this.isPDFTronFile) {
        this.$refs.webviewer.loadfile(this.url, this.file);
      } else if (this.isPreviewServerFile) {
        this.$refs['preview-viewer'].loadfile(this.url, this.file);
      } else if (this.is3DFile) {
        this.$refs['three-viewer'].loadfile(this.url, this.file);
      }
    });
  },
};
</script>

<style>
#content video {
  width: 100%;
  height: 75vh;
}
.header-btn {
  color: #777;
  background: none;
  border: none;
}
#preview-modal footer {
  border: none;
  justify-content: center;
}
.footer-btn {
  color: #777;
  background-color: #eee;
  border: none;
  margin-right: 10px;
}

#preview-modal .modal-content {
  overflow: auto;
  min-height: 450px;
}
</style>
