<template>
  <li
    class="file-list-item"
    v-on:click="selectItem(id)"
    data-testid="item-select"
  >
    <font-awesome-icon class="file-check" v-if="selected" icon="check-square" />
    <font-awesome-icon class="file-check" v-else :icon="['far', 'square']" />
    <div class="file-icon">
      <img data-testid="file-icon-img" :src="icon" />
    </div>
    <div class="file-details">
      <div class="file-name">
        <a
          data-testid="item-name-a-tag"
          href="javascript:void(0)"
          @click.stop="preview"
          v-if="hasPreview"
        >
          {{ title }}
        </a>
        <a data-testid="item-name-a-tag" :href="path" @click.stop="onClick" v-else>{{ title }}</a>
        <div
          data-testid="title-buttons"
          v-for="button in titleButtons"
          :key="button.title"
          class="file-btn"
        >
          <a
            data-toggle="tooltip"
            :href="button.href"
            class="btn-mini btn-file-action hidden-phone"
            @click.stop="onClick"
          >
            <font-awesome-icon v-if="button.icon" :icon="button.icon" />
          </a>
        </div>
      </div>
      <div class="file-info">
        <span data-testid="file-info" class="file-detail-item">{{
          secondaryInfo
        }}</span>
      </div>
    </div>
    <ButtonDropdown :buttons="buttons" />
  </li>
</template>

<script>
import ButtonDropdown from '@/components/core/ButtonDropdown.vue';

export default {
  name: 'ListItem',
  components: {
    ButtonDropdown,
  },
  props: {
    selectItem: Function,
    buttons: Array,
    title: String,
    titleButtons: Array,
    secondaryInfo: String,
    icon: String,
    path: String,
    id: Number,
    selected: Boolean,
    hasPreview: Boolean,
    onPreview: Function,
  },
  methods: {
    preview() {
      this.$emit('onPreview', this.file);
    },
    onClick() {
    },
  },
};
</script>

<style>
ul {
  list-style: none;
}
.visible-phone {
  display: none;
}

.file-list-item {
  display: flex;
  align-items: center;
}
.file-icon {
  width: 50px;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}
.file-icon img {
  max-width: 50px;
  max-height: 59px;
}
.file-details {
  flex-grow: 1;
  text-align: left;
  margin-left: 15px;
  font-size: 12px;
  line-height: 16px;
}
.file-name {
  font-weight: 700;
  line-height: 22px;
  font-size: 16px;
  word-break: break-word;
}
.file-name a {
  color: #777;
}
.file-name .btn {
  color: #777;
  background-color: #eee;
}
.file-btn {
  vertical-align: text-bottom;
  display: inline;
  margin-left: 4px;
}
.file-list-item .btn-mini {
  height: 20px;
  line-height: 20px;
  color: #777;
  background-color: #eee;
  padding: 0px 6px;
}
.file-list-item .btn-mini svg {
  height: 0.8em;
  width: 0.8em;
}
.file-info span {
  opacity: 0.75;
}
.file-actions {
  float: right;
  margin: 15px;
}
.file-actions .btn {
  color: #777;
  background-color: #eee;
  font-weight: 700;
}
.thumbs {
  display: flex;
  flex-wrap: wrap;
}
.thumbs > li {
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  zoom: 1;
  height: auto;
  margin: 0 0 30px 30px;
  padding: 10px;
  width: 250px;
  position: relative;
  text-align: left;
}
.thumbs .file-details {
  width: 100%;
  margin: 10px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.thumbs .file-icon {
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  background-size: 33% auto;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%) inset;
  display: block;
  height: 150px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  width: 100%;
  background-color: #fafafa;
}
.thumbs .file-check {
  left: 15px;
  top: 15px;
  width: 20px;
}

.thumbs .file-actions,
.thumbs .file-check {
  margin-top: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  vertical-align: top;
  margin: 0;
}
.thumbs .file-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.thumbs .file-icon img {
  max-width: 246px;
  max-height: 146px;
}
</style>
