<template>
  <b-modal
    :id="id"
    v-model="model"
    :static="true"
    size="xl"
    lazy
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-header="{ close }">
      <h5>{{ title }}</h5>
      <slot name="branded-modal-actions" />
      <button @click="close()" class="header-btn">
        <font-awesome-icon icon="times" />
      </button>
    </template>
    <template #default>
      <slot name="branded-modal-content" />
    </template>
    <template #modal-footer>
      <slot name="branded-modal-footer" />
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'BrandedModal',
  props: {
    id: String,
    title: String,
  },
  updated() {
    // These attributes are added by bootstrap-vue when `directory` mode is enabled.
    // Directory mode is required for dragging and dropping folders. However, if
    // `directory` is on the `input` element, the `browse` dialog will only allow
    // for folders to be selected. This modification allows for files or folders
    // to be dropped, but open the file selection dialog on browse.
    const element = document.getElementById('formFile');
    if (element) {
      element.removeAttribute('directory');
      element.removeAttribute('webkitdirectory');
    }
  },
  data() {
    return {
      model: false,
    };
  },
};
</script>

<style>
.modal-header {
  background: var(--main-color);
}
.modal-header h5,
.modal-header button {
  color: white;
  font-weight: 700;
}
.modal-body {
  background: var(--main-color);
}
.modal-body form {
  background: white;
  border-radius: 0.25rem;
  padding: 20px;
}
.modal-content {
  overflow: auto;
  min-height: 450px;
}
.modal-footer {
  justify-content: center;
  border: none;
  background: var(--main-color);
}
</style>
