<template>
  <div class="linkhome" data-testid="linkhome">
    <div v-if="needsLogin" id="loginview">
      <Login @onSuccess="onLogin" />
    </div>
    <div v-else id="listview">
      <b-navbar id="nav">
        <b-navbar-brand href="#" id="brand-logo">
          <img
            v-if="getLogoURL"
            id="brandlogo"
            alt="brand"
            :src="getLogoURL"
            :style="getLogoStyle"
        /></b-navbar-brand>
      </b-navbar>

      <b-overlay :show="loading">
        <template #overlay>
          <IconSpinner />
        </template>
        <div class="btn-toolbar" v-if="linkError == ''">
          <b-button-toolbar
            id="button-toolbar"
            key-nav
            aria-label="Toolbar with button groups"
          >
            <b-button-group class="mx-1">
              <b-button
                id="upload-btn"
                class="highlight-color"
                data-testid="modal-show"
                v-if="link.acl.write"
                @click="$bvModal.show('upload-modal')"
                ><font-awesome-icon icon="upload" />Upload</b-button
              >
            </b-button-group>
            <b-button-group class="mx-1">
              <b-button
                id="download-all-btn"
                class="success-color"
                v-if="link.acl.read && numSelected === 0 && linkCollection.length"
                @click="callDownloadAll"
                data-testid="download-all"
                ><font-awesome-icon icon="download" />Download All</b-button
              >
              <a
                id="download-selected-btn"
                class="success-color btn"
                v-else-if="link.acl.read && numSelected === 1 && getSelected[0].isfile"
                :href="downloadURL"
                data-testid="download"
                ><font-awesome-icon icon="download" />Download Selected</a
              >
              <a
                id="download-selected-btn"
                class="success-color btn"
                v-else-if="link.acl.read && numSelected > 0"
                @click="callDownloadAll"
                data-testid="download-selected"
                ><font-awesome-icon icon="download" />Download Selected</a
              >
            </b-button-group>
          </b-button-toolbar>
        </div>
        <div v-if="downloadAllError">
          <b-alert data-testid="link-error-alert" show variant="danger">{{
            downloadAllError
          }}</b-alert>
        </div>
        <PaginatedFileList
          :basePath="basePath"
          :rows="rows"
          :perPage="perPage"
          :currentPage="currentPage"
          :changePage="changePage"
          :changeLimit="changeLimit"
          :error="linkError"
          :loading="loading"
          :selectAll="selectAllItems"
          :changeSort="changeSort"
          :changeFilter="changeFilter"
          :changeView="changeView"
          :acl="link.acl"
        >
          <template #breadcrumbs>
            <FilePathBreadCrumbs :path="path" :basePath="basePath" />
          </template>
          <template #list>
            <div v-if="!link.acl.list">
              <div v-if="successfulFiles.length">
                <b-alert show variant="success">
                  <h4 class="alert-heading">Files Successfully Uploaded:</h4>
                  <ul class="uploadedList">
                    <li v-for="file in successfulFiles" :key="file.name">
                      {{ file.name }}
                    </li>
                  </ul>
                </b-alert>
              </div>
              <div v-if="failedFiles.length">
                <b-alert show variant="danger">
                  <h4 class="alert-heading">Files Failed to Upload:</h4>
                  <ul class="uploadedList">
                    <li v-for="file in failedFiles" :key="file.name">
                      {{ file.name }}
                    </li>
                  </ul>
                </b-alert>
              </div>
            </div>
            <FileListViewSwitcher
              v-else
              :items="filteredItems"
              :basePath="basePath"
              :selectListItem="selectItem"
              :acl="link.acl"
            />
          </template>
        </PaginatedFileList>
        <UploadModal
          @onUploadOnly="showUploadedFiles"
          :hasListPermissions="link.acl.list"
        />
        <div id="page-footer">
          <div class="footer-left">
            <p>&copy; {{ getBrandName }}, all rights reserved.</p>
          </div>
          <div class="footer-right">
            <p v-if="link.owner.email">
              Sent by: {{ link.owner.name }} -
              <a class="main-text" :href="`mailto:${link.owner.email}`">{{
                link.owner.email
              }}</a>
            </p>
            <p v-else>Sent by: {{ link.owner.name }}</p>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import UploadModal from '@/components/modals/UploadModal.vue';
import PaginatedFileList from '@/components/PaginatedFileList.vue';
import FilePathBreadCrumbs from '@/components/FilePathBreadCrumbs.vue';
import FileListViewSwitcher from '@/components/FileListViewSwitcher.vue';
import IconSpinner from '@/components/core/IconSpinner.vue';
import Login from '@/components/Login.vue';

import { get, getDownloadURL } from '@/api/link';

import { downloadViaForm } from '@/utils/utils';

export default {
  components: {
    UploadModal,
    PaginatedFileList,
    Login,
    FilePathBreadCrumbs,
    FileListViewSwitcher,
    IconSpinner,
  },
  data() {
    return {
      needsLogin: false,
      rows: 1,
      perPage: 25,
      currentPage: 1,
      page: 1,
      path: '',
      linkError: '',
      loading: true,
      successfulFiles: [],
      failedFiles: [],
      downloadAllError: undefined,
      color: 'blue',
    };
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  computed: {
    downloadURL() {
      return getDownloadURL(
        this.$route.params.uid,
        this.$route.hash.replace('#/', ''),
        this.getSelected[0].name,
      );
    },
    filteredItems() {
      return this.linkCollection && this.linkCollection.filter((item) => item.visible);
    },
    basePath() {
      let { path } = this.$route;
      if (this.$route.params.path) {
        [path] = this.$route.path.split(`/${this.$route.params.path}`);
      }
      return path.endsWith('/') ? path : `${path}/`;
    },
    ...mapState({
      link: (state) => state.LinkStore.link,
      linkCollection: (state) => state.LinkStore.collection,
      preferences: (state) => state.PreferenceStore.preferences,
    }),
    ...mapGetters({
      getBrandName: 'BrandStore/getBrandName',
      getLogoURL: 'BrandStore/getLogoURL',
      getLogoStyle: 'BrandStore/getLogoStyle',
      isSelected: 'LinkStore/collection/isSelected',
      isAllSelected: 'LinkStore/collection/isAllSelected',
      numSelected: 'LinkStore/collection/numSelected',
      getSelected: 'LinkStore/collection/getSelected',
    }),
  },
  methods: {
    getData(page, limit) {
      this.loading = true;
      const route = this.$route.hash.replace('#/', '');
      this.path = `/${route}`;
      get(this.$route.params.uid, route, page, limit, this.preferences.sort)
        .then((response) => {
          const { data } = response;
          if (data.type && data.type === 'redirect') {
            window.location.assign(data.url);
            return;
          }
          this.rows = data.total;
          this.perPage = data.per_page;
          this.currentPage = data.page;
          this.downloadAllUrl = data.download_all;
          this.$store.commit('LinkStore/setLink', data);
          this.$store.dispatch('LinkStore/collection/setInitial');
          this.$store.commit('BrandStore/setBrand', data.brand);
          this.loading = false;
        })
        .catch((error) => {
          if (error.response !== undefined && error.response.status === 401) {
            this.needsLogin = true;
            this.$store.commit(
              'BrandStore/setBrand',
              error.response.data.brand,
            );
          } else if (
            error.response !== undefined
            && error.response.status === 404
          ) {
            this.$emit('onerror');
          } else if (error.response !== undefined) {
            const { data } = error.response;
            this.$store.commit('BrandStore/setBrand', data.brand);
            this.linkError = data.error || data.detail;
          } else {
            this.linkError = error.message;
          }
          this.loading = false;
        });
    },
    changeLimit(limit) {
      this.getData(this.currentPage, limit);
    },
    changePage(event, page) {
      this.getData(page, this.perPage);
    },
    changeSort(sort) {
      this.$store.commit('PreferenceStore/setSort', sort);
      this.getData(this.currentPage, this.perPage);
    },
    changeFilter(filter) {
      this.$store.commit('LinkStore/collection/filter', {
        value: filter,
        fields: ['name'],
      });
    },
    changeView(view) {
      this.$store.commit('PreferenceStore/setView', view);
    },
    callDownloadAll() {
      this.downloadAllError = undefined;
      const paths = [];
      this.getSelected.forEach((element) => {
        paths.push(element.path);
      });
      if (paths.length === 0) {
        const filepath = this.linkCollection[0].path;
        const parentPath = filepath
          .split('/')
          .slice(0, -1)
          .join('/');
        paths.push(parentPath);
      }
      downloadViaForm(this.downloadAllUrl, paths, (error) => {
        this.downloadAllError = error;
      });
    },
    onLogin(value) {
      this.needsLogin = false;
      this.link.uuid = value;
      this.getData();
    },
    selectItem(value) {
      if (this.isSelected(value)) {
        this.$store.commit('LinkStore/collection/setItemDeselected', value);
      } else {
        this.$store.commit('LinkStore/collection/setItemSelected', value);
      }
    },
    selectAllItems() {
      if (this.isAllSelected) {
        this.$store.dispatch('LinkStore/collection/selectNone');
      } else {
        this.$store.dispatch('LinkStore/collection/selectAll');
      }
    },
    showUploadedFiles(successfulFiles, failedFiles) {
      if (!this.link.acl.list) {
        this.rows = successfulFiles.length;
        this.successfulFiles = successfulFiles;
        this.failedFiles = failedFiles;
      } else {
        this.getData(this.currentPage, this.perPage);
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style>
.btn-primary {
  border: 1px transparent !important;
}
.btn-secondary {
  border: 1px transparent !important;
}
button svg {
  margin-right: 5px;
}
.linkhome {
  font-weight: 700;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.linkhome .btn {
  font-weight: 700;
}
.file-list-item {
  border: dashed;
  border-color: #eee;
  border-width: 1px 0 0;
}
.uploadedList li {
  list-style: disc;
  display: list-item;
  list-style-position: inside;
}
.btn-toolbar {
  width: 100%;
  height: 56px;
}
a.btn {
  color: white;
  height: 36px;
}
a.btn svg {
  margin-right: 5px;
}
.b-overlay {
  min-height: 95vh;
}
.b-overlay div:not(:first-child) {
  top: 50vh !important;
}
#page-footer {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
  background: #fff;
  border-style: solid;
  border-width: 1px 0 0;
  border-color: #eee;
}
</style>
