<template>
  <div id="iconspinner">
    <div id="spinner">
      <b-spinner variant="secondary"></b-spinner>
      <img :src="getIconURL" />
    </div>
    <b-alert show variant="light">Loading...</b-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'IconSpinner',
  computed: {
    ...mapGetters({
      getIconURL: 'BrandStore/getIconURL',
    }),
  },
};
</script>

<style scoped>
#spinner span {
  width: 3rem;
  height: 3rem;
}
#spinner img {
  margin-left: -2.25rem;
  margin-top: -2.25rem;
}
#iconspinner .alert {
  background: none;
  position: static;
}
</style>
