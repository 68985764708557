import { bytesToOther } from './consts';

const moment = require('moment');

function fromBytes(bytes) {
  if (bytes) {
    for (const key in bytesToOther) {
      if (bytes < bytesToOther[key]) {
        const byteVal = (
          bytes / (bytesToOther[key] / 2 ** 10).toFixed(2)
        ).toFixed(2);
        return `${byteVal}${key}`;
      }
    }
  }
  return '0.0B';
}

function bytesToPretty(bytes, defaultBytes) {
  if (bytes === null) {
    return defaultBytes;
  }

  return fromBytes(bytes);
}

function formatDate(date, format) {
  return moment(date).format(format);
}

function getPreviewWidth(type) {
  if (type === 'th') {
    return 240;
  } if (type === 'preview') {
    return Math.round(window.innerWidth);
  }
  return 40;
}

function getPreviewHeight(type) {
  if (type === 'th') {
    return 150;
  } if (type === 'preview') {
    return Math.round(window.innerWidth * 0.75);
  }
  return 50;
}

function randomString(len) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
  let random = '';
  let i = 0;
  for (i; i < len; i++) {
    const rand = Math.floor(Math.random() * chars.length);
    random += chars.substring(rand, rand + 1);
  }
  return random;
}

function downloadViaForm(url, paths, callback) {
  window.addEventListener(
    'message',
    (message) => {
      if (
        message.data
        && typeof message.data === 'string'
        && message.data.startsWith('download-all-error')
      ) {
        callback(message.data.replace('download-all-error: ', ''));
      }
    },
    false,
  );
  const id = randomString(5);
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  const downloadId = `download-all-${id}`;
  iframe.id = downloadId;
  iframe.name = downloadId;
  const form = document.createElement('form');
  form.method = 'POST';
  form.target = downloadId;
  form.action = url;

  for (let i = 0; i < paths.length; i++) {
    const inputfield = document.createElement('input');
    inputfield.name = 'path';
    inputfield.type = 'hidden';
    inputfield.value = paths[i];
    form.appendChild(inputfield);
  }

  document.body.appendChild(iframe);
  document.body.appendChild(form);
  form.submit();

  // Clean up after POST, I could not find a reliable way to detect completion. In my testing
  // the download continues after the elements are removed. The download only need be started
  // within 15s.
  window.setTimeout(() => {
    document.body.removeChild(form);
    document.body.removeChild(iframe);
  }, 15000);
}

export {
  bytesToPretty,
  formatDate,
  getPreviewWidth,
  getPreviewHeight,
  downloadViaForm,
};
