<template>
  <div ref="viewer" id="wrapper">
    <div class="modal-actions" v-if="!file.isImage">
      <button
        class="btn prev"
        id="previous"
        :disabled="page === 1"
        v-on:click="previousPage"
        data-testid="previous-btn"
      >
        Previous Page
      </button>
      <button
        class="btn next"
        id="next"
        :disabled="page === maxPage"
        v-on:click="nextPage"
        data-testid="next-btn"
      >
        Next Page
      </button>
    </div>
    <div class="modal-content" style="height: 100%;">
      <img
        id="preview"
        :src="preview_url"
        :alt="file.name"
        data-testid="preview-img"
      />
      <img
        style="display:none"
        id="hidden_preview"
        data-testid="hidden-preview-img"
        :src="hidden_url"
        :alt="file.name"
        @error="setMaxPage"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewViewer',
  data() {
    return {
      maxPage: Number.MAX_VALUE,
      hidden_url: '',
      preview_url: '',
      url: '',
      page: 1,
      file: {
        isimage: false,
      },
    };
  },
  methods: {
    generatePaginatedURL() {
      this.hidden_url = `${this.url}&pages=${this.page + 1}`;
      this.preview_url = `${this.url}&pages=${this.page}`;
    },
    previousPage() {
      if (this.page > 1) {
        this.page -= 1;
        this.generatePaginatedURL();
      }
    },
    nextPage() {
      if (this.page < this.maxPage) {
        this.page += 1;
        this.generatePaginatedURL();
      }
    },
    setMaxPage() {
      this.maxPage = this.page;
    },
    loadfile(url, file) {
      this.page = 1;
      this.url = url;
      this.file = file;
      this.generatePaginatedURL();
    },
  },
};
</script>

<style scoped>
#wrapper {
  width: 100%;
  height: 75vh;
}
.modal-content {
  border: none;
  height: 100%;
}
button {
  color: #777;
  background-color: #eee;
  border: none;
  margin-right: 10px;
}
.btn:disabled:hover {
  color: gray;
}
</style>
