<template>
  <b-breadcrumb data-testid="breadcrumbs">
    <b-breadcrumb-item
      v-for="item in breadcrumbs"
      :key="item.href"
      :href="item.href"
      :active="item.active"
      :style="item.style"
    >
      <span v-if="item.active" :style="item.style"> {{ item.text }}</span>
      <a v-else :style="item.style">{{ item.text }}</a>
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script>
export default {
  name: 'FilePathBreadCrumbs',
  props: {
    path: String,
    basePath: String,
  },
  computed: {
    breadcrumbs() {
      let pathSoFar = `${this.basePath}#/`;
      const breadcrumbs = [
        {
          text: 'Home',
          href: pathSoFar,
        },
      ];
      if (this.path !== '' && this.path !== undefined) {
        const splitPath = this.path.split('/');
        // Remove the first and last empty strings so we are left with ["folder"]
        splitPath.shift();
        splitPath.pop();
        splitPath.forEach((element) => {
          pathSoFar = `${pathSoFar}${element}/`;
          const breadcrumb = {
            text: decodeURIComponent(element),
            href: pathSoFar,
          };
          breadcrumbs.push(breadcrumb);
        });
        breadcrumbs[breadcrumbs.length - 1].active = true;
      }
      return breadcrumbs;
    },
  },
};
</script>

<style>
.breadcrumb {
  background: none !important;
  font-size: 20px;
  font-weight: 700;
}
.breadcrumb-item::before {
  color: var(--success-color) !important;
}

.breadcrumb-item a {
  color: var(--main-color) !important;
}
.breadcrumb-item.active {
  color: var(--success-color) !important;
}
</style>
