<template>
  <div id="three-preview">
    <div ref="preview"></div>
    <h5 ref="loading" style="text-align: center;" v-show="!loaded">
      Loading...
    </h5>
    <div ref="controls" v-show="loaded">
      <h6>To rotate: left mouse drag and drop</h6>
      <h6>To pan: right mouse drag and drop</h6>
      <h6>To zoom: mouse scroll</h6>
    </div>
  </div>
</template>

<script>
import * as Three from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

export default {
  name: 'ThreeDViewer',
  data() {
    return {
      loaded: false,
      domElement: null,
    };
  },
  methods: {
    setUpScene() {
      this.render_id = 0;
      this.scene = new Three.Scene();
      const width = window.innerWidth / 2;
      const height = window.innerHeight / 2;
      this.camera = new Three.PerspectiveCamera(75, width / height, 0.1, 1000);
      this.directionalLight = new Three.DirectionalLight(0xffffff, 0.5);
      this.renderer = new Three.WebGLRenderer();
      this.renderer.setSize(width, height);
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.$refs.preview.appendChild(this.renderer.domElement);
      this.boundingBox = new Three.Box3();
      this.material = new Three.MeshPhongMaterial({ color: 0xff9a01 });
    },
    loadSTL(filepath, _this) {
      new STLLoader().load(
        filepath,
        (obj) => {
          _this.loaded = true;
          if (obj.hasColors) {
            _this.material = new Three.MeshPhongMaterial({
              opacity: obj.alpha,
              vertexColors: true,
            });
          }
          const mesh = new Three.Mesh(obj, _this.material);
          mesh.geometry.computeBoundingBox();
          _this.boundingBox = mesh.geometry.boundingBox;
          _this.scene.add(mesh);
          _this.setLightsAndCamera();
        },
        undefined,
        this.onError,
      );
    },
    loadOBJ(filepath, _this) {
      new OBJLoader().load(
        filepath,
        (obj) => {
          _this.loaded = true;
          obj.traverse((child) => {
            if (child instanceof Three.Mesh) {
              child.material = _this.material;
            }
          });
          _this.setBoundingBoxFrom(obj, _this);
        },
        undefined,
        this.onError,
      );
    },
    loadFBX(filepath, _this) {
      new FBXLoader().load(
        filepath,
        (obj) => {
          _this.loaded = true;
          obj.traverse((child) => {
            if (child.isMesh) {
              child.material = _this.material;
            }
          });
          _this.setBoundingBoxFrom(obj, _this);
        },
        undefined,
        this.onError,
      );
    },
    loadCollada(filepath, _this) {
      new ColladaLoader().load(
        filepath,
        (obj) => {
          _this.loaded = true;
          _this.setBoundingBoxFrom(obj.scene, _this);
        },
        undefined,
        this.onError,
      );
    },
    loadGLTF(filepath, _this) {
      new GLTFLoader().load(
        filepath,
        (obj) => {
          _this.loaded = true;
          _this.setBoundingBoxFrom(obj.scene, _this);
        },
        undefined,
        this.onError,
      );
    },
    setBoundingBoxFrom(obj, _this) {
      _this.scene.add(obj);
      _this.boundingBox = new Three.Box3().setFromObject(obj);
      _this.setLightsAndCamera();
    },
    setLightsAndCamera() {
      const MULTIPLIER = 1.75;
      const center = this.boundingBox.getCenter();
      const size = this.boundingBox.getSize();
      let maxDim = Math.max(size.x, size.y, size.z);
      maxDim *= MULTIPLIER;
      if (size.x * MULTIPLIER === maxDim) {
        this.camera.position.z = maxDim;
      } else if (size.y * MULTIPLIER === maxDim) {
        this.camera.position.x = maxDim;
      } else {
        this.camera.position.y = maxDim;
      }
      this.camera.updateProjectionMatrix();
      this.camera.lookAt(center);
      this.controls.update();
      const ambientLight = new Three.AmbientLight(0xd8d8d8);
      this.scene.add(ambientLight);
      this.directionalLight.position.set(this.camera.position);
      this.scene.add(this.directionalLight);
    },
    animate() {
      this.render_id = requestAnimationFrame(this.animate);
      this.controls.update();
      this.directionalLight.position.copy(this.camera.position);
      this.renderer.render(this.scene, this.camera);
    },
    loadfile(url, file) {
      this.setUpScene();
      const loaders = {
        stl: this.loadSTL,
        obj: this.loadOBJ,
        fbx: this.loadFBX,
        dae: this.loadCollada,
        gltf: this.loadGLTF,
        glb: this.loadGLTF,
      };
      loaders[file.extension](url, this);
      this.animate();
    },
  },
};
</script>
<style scoped>
#three-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#controls {
  text-align: center;
  margin-top: 15px;
}
</style>
