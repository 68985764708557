<template>
  <div>
    <ul
      v-if="getViewPref != 'table'"
      data-testid="file-list"
      class="search-results"
      id="my-list"
      v-bind:class="{ thumbs: getViewPref == 'th' }"
    >
      <FileListItem
        v-for="item in items"
        :key="item.name"
        :file="item"
        :basePath="basePath"
        :acl="acl"
        :selectListItem="selectListItem"
        :getFilePath="getFilePath"
        :getFileButtons="buttons"
        :getFileIcon="icon"
        :getActionButtons="actionButtons"
        @onPreview="onPreview"
      />
    </ul>
    <FileTable
      v-else
      :items="items"
      :basePath="basePath"
      :selectListItem="selectListItem"
      :getFilePath="getFilePath"
      :getFileButtons="buttons"
      :getFileIcon="icon"
      data-testid="file-table"
      :getActionButtons="actionButtons"
      @onPreview="onPreview"
    />
    <PreviewModal ref="preview-modal" />
  </div>
</template>

<script>
import FileListItem from '@/components/FileListItem.vue';
import FileTable from '@/components/FileTable.vue';
import { getPreviewURL, getDownloadURL } from '@/api/link';
import PreviewModal from '@/components/modals/preview/PreviewModal.vue';

export default {
  name: 'FileListSwitcher',
  components: {
    FileListItem,
    FileTable,
    PreviewModal,
  },
  props: {
    items: Array,
    basePath: String,
    acl: Object,
    selectListItem: Function,
  },
  computed: {
    getViewPref() {
      return this.$store.getters['PreferenceStore/getPreference']('view');
    },
    route() {
      return this.$route.hash.replace('#/', '');
    },
  },
  methods: {
    getFilePath(file) {
      const name = encodeURIComponent(file.name);
      if (file.isdir) {
        return `${this.basePath}#/${this.route}${name}/`;
      }
      return `${this.basePath}${this.route}${name}`;
    },
    buttons(file) {
      if (file.isdir) {
        return [
          { href: this.getFilePath(file), icon: 'folder-open', title: 'Open' },
        ];
      }
      return [
        {
          href: getDownloadURL(this.$route.params.uid, this.route, file.name),
          icon: 'download',
          title: 'Download',
        },
      ];
    },
    onPreview(file) {
      this.$refs['preview-modal'].showModalWithFile(file);
    },
    actionButtons() {
      return [];
    },
    icon(file) {
      const viewPref = this.$store.getters['PreferenceStore/getPreference'](
        'view',
      );
      if (file.isfile && file.acl.read) {
        return getPreviewURL(
          this.$route.params.uid,
          this.route,
          file.name,
          viewPref,
        );
      }
      const fileType = file.extension
        ? file.extension.replace('.', '')
        : 'folder';
      const previewSize = viewPref !== 'th' ? 40 : 96;
      try {
        /* eslint-disable-next-line global-require, import/no-dynamic-require */
        return require(`@/assets/file-types/${previewSize}/${fileType}.png`);
      } catch (e) {
        /* No icon for this file type */
        /* eslint-disable-next-line global-require, import/no-dynamic-require */
        return require(`@/assets/file-types/${previewSize}/blank.png`);
      }
    },
  },
};
</script>
