import axios from 'axios';

const defaultPage = 1;
const defaultLimit = 25;

function createPaginationParams(page, limit, sort) {
  let params = `?page=${page || defaultPage}&limit=${limit || defaultLimit}`;
  if (sort) {
    params += `&sort=${sort}`;
  }
  return params;
}

const api = axios.create();

export { api, createPaginationParams };
