<template>
  <div>
    <div v-if="error == ''">
      <div id="listheader" v-if="acl.list">
        <font-awesome-icon
          class="selectBox"
          v-if="isAllSelected"
          icon="check-square"
          v-on:click="selectAll"
          data-testid="list-select-none"
        />
        <font-awesome-icon
          class="selectBox"
          v-else
          :icon="['far', 'square']"
          v-on:click="selectAll"
          data-testid="list-select-all"
        />
        <slot name="breadcrumbs" />
        <div id="actionsAndHeaderPagination">
          <div id="sort">
            <b-button-group
              v-if="changeView != undefined"
              id="view"
              size="sm"
              data-testid="views"
            >
              <b-button
                v-for="view in viewTypes"
                v-bind:key="view"
                v-bind:class="{ active: getCurrentPreference('view') == view }"
                v-on:click="changeView(view)"
                data-testid="view-selection"
                ><font-awesome-icon :icon="view"
              /></b-button>
            </b-button-group>
            <b-dropdown
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
              id="filter"
              right
              data-testid="filter-button"
            >
              <template #button-content>
                <font-awesome-icon icon="filter" />
              </template>
              <b-dropdown-form>
                <b-form-group label-for="dropdown-filter" @submit.stop.prevent>
                  <b-form-input
                    id="dropdown-filter"
                    size="sm"
                    placeholder="Filter listing"
                    data-testid="filter-textbox"
                    v-on:input="changeFilter"
                  ></b-form-input>
                </b-form-group>
                <b-button
                  data-testid="clear-filter"
                  variant="secondary"
                  size="sm"
                  v-on:click="clearFilter"
                >
                  <font-awesome-icon icon="times" />
                </b-button>
              </b-dropdown-form>
            </b-dropdown>
            <b-dropdown
              toggle-class="text-decoration-none"
              right
              no-caret
              size="sm"
              data-testid="sort-dropdown"
            >
              <template #button-content>
                <font-awesome-icon icon="sort" />
              </template>
              <b-dropdown-item-button
                v-for="[key, value] in sortTypes"
                v-bind:key="key"
                v-on:click="changeSort(key)"
                v-bind:class="{
                  active: getCurrentPreference('sort').includes(key)
                }"
                :style="setStyling('sort', key)"
                data-testid="sort-item"
                >{{ value }}
                <font-awesome-icon
                  v-if="
                    getCurrentPreference('sort').includes(key) &&
                      !getCurrentPreference('sort').includes('-')
                  "
                  icon="arrow-down"
                />
                <font-awesome-icon
                  v-else-if="getCurrentPreference('sort').includes(key)"
                  icon="arrow-up"
                />
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
          <div id="headerPagination" v-if="rows > perPage">
            <div id="rows">
              Rows:
              <b-dropdown
                split
                :text="pageString"
                id="rowsDropDown"
                class="m-md-2"
                variant="outline-secondary"
              >
                <b-dropdown-item-button
                  v-for="value in rowOptions"
                  v-bind:key="value"
                  v-bind:class="{
                    active: perPage == value
                  }"
                  :style="setStyling('row', value)"
                  @click="changeLimit(value)"
                  >{{ value }}</b-dropdown-item-button
                >
              </b-dropdown>
            </div>
            <b-pagination
              variant="secondary"
              data-testid="top-pagination-controls"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-list"
              align="right"
              @page-click="changePage"
            ></b-pagination>
          </div>
        </div>
      </div>
      <slot name="list" />
      <ul>
        <li class="no-content">
          <b-alert show variant="light" v-if="rows == 0"
            >There are no files in this directory.</b-alert
          >
          <b-button-group class="mx-1">
            <b-button
              class="success-color"
              data-testid="modal-show"
              v-if="acl.write && !acl.list"
              @click="$bvModal.show('upload-modal')"
              ><font-awesome-icon icon="upload" />Click Here to Upload</b-button
            >
          </b-button-group>
        </li>
      </ul>
      <div id="listFooter" v-if="acl.list">
        <div id="rows">
          Rows:
          <b-dropdown
            split
            :text="pageString"
            id="rowsDropDown"
            data-testid="rowsDropdown"
            class="m-md-2"
            variant="outline-secondary"
          >
            <b-dropdown-item-button
              v-for="value in rowOptions"
              v-bind:key="value"
              v-bind:class="{
                active: perPage == value
              }"
              :style="setStyling('row', value)"
              @click="changeLimit(value)"
              >{{ value }}</b-dropdown-item-button
            >
          </b-dropdown>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-list"
          align="right"
          @page-click="changePage"
        ></b-pagination>
      </div>
    </div>
    <div v-else>
      <b-alert data-testid="link-error-alert" show variant="danger">{{
        error
      }}</b-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PaginatedFileList',
  data() {
    return {
      currentPage: 1,
      sortTypes: [
        ['name', 'Name'],
        ['size', 'Size'],
        ['date', 'Date'],
        ['owner', 'Owner'],
      ],
      viewTypes: ['list', 'table', 'th'],
      rowOptions: [25, 50, 100],
    };
  },
  props: {
    basePath: String,
    rows: Number,
    perPage: Number,
    changePage: Function,
    changeLimit: Function,
    error: String,
    selectAll: Function,
    changeSort: Function,
    changeFilter: Function,
    changeView: Function,
    acl: Object,
  },
  computed: {
    ...mapGetters({
      getPreference: 'PreferenceStore/getPreference',
      getBackgroundColor: 'BrandStore/getBackgroundColor',
      isAllSelected: 'LinkStore/collection/isAllSelected',
    }),
    pageString() {
      if (this.perPage !== undefined) {
        return this.perPage.toString();
      }
      return '25';
    },
  },
  methods: {
    clearFilter() {
      this.changeFilter('');
      document.getElementById('dropdown-filter').value = '';
    },
    setStyling(type, val) {
      let selected = false;
      if (type === 'sort') {
        selected = this.getCurrentPreference('sort').includes(val);
      } else {
        selected = this.perPage === val;
      }
      if (selected) {
        return `background: ${this.getBackgroundColor('main')}; color: white`;
      }
      return '';
    },
    getCurrentPreference(type) {
      const pref = this.getPreference(type);
      if (pref === undefined) {
        return '';
      }
      return pref;
    },
  },
};
</script>

<style>
#listheader {
  display: flex;
  align-items: center;
  margin: 10px;
}
#listheader .selectBox {
  margin-left: 30px;
}
#listheader .breadcrumb {
  flex-grow: 3;
  margin: 0;
}
#listheader .pagination {
  margin: 0;
}
#actionsAndHeaderPagination {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: right;
}
#listheader #headerPagination {
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: flex-end;
}
#listheader #rows {
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: flex-end;
  padding-right: 20px;
}
#listFooter {
  display: flex;
  align-items: center;
  margin: 10px;
  justify-content: flex-end;
}
#listFooter .pagination {
  margin: 0;
}
#listFooter #rows {
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: flex-end;
  padding-right: 20px;
}

#rowsDropDown li {
  text-align: center;
}
#rowsDropDown__BV_button_ {
  pointer-events: none;
}
#pages {
  padding-right: 5px;
}
#sort button svg {
  margin-right: 0;
}
.active button {
  color: white;
}
.active button:hover {
  background-color: inherit !important;
}
.dropdown-item:active {
  background-color: #f8f9fa !important;
  color: #212529 !important;
}
#filter {
  margin-right: 5px;
}
#filter form {
  display: flex;
  align-content: center;
  width: 250px;
  justify-content: space-between;
}
#filter form .form-group {
  margin-bottom: 0;
}
#view {
  margin-right: 5px;
}
.page-item .page-link > a:focus,
.page-item .page-link > a:hover,
.page-item .page-link > span:focus,
.page-item .page-link > span:hover,
.page-link {
  z-index: 3;
  color: #23527c !Important;
  border-color: #ddd !Important;
}

.page-item.active .page-link {
  color: white !important;
  background-color: #6c757d !Important;
  border: solid 1px #6c757d !Important;
}

.pagination > .active > a:hover {
  background-color: #6c757d !Important;
  border: solid 1px #6c757d;
}
.no-content {
  border: dashed;
  border-color: #eee;
  border-width: 0px 0 1px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.no-content div {
  margin-bottom: 0;
}
.no-content button {
  margin-bottom: 1rem;
}
</style>
