export const bytesToOther = {
  B: 2 ** 10,
  KB: 2 ** 20,
  MB: 2 ** 30,
  GB: 2 ** 40,
  TB: 2 ** 50,
};

export const pdftronExtensions = [
  'pdf',
  'xfdf',
  'fdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'bmp',
  'gif',
  'jpg',
  'jpeg',
  'png',
  'tif',
  'odt',
  'rtf',
  'ods',
  'odp',
  'html',
  'txt',
  'dwg',
  'dwf',
  'dxf',
  'dgn',
];
export const audioExtensions = ['mp3'];
export const videoExtensions = [
  '3g2',
  '3gp',
  '4xm',
  'a64',
  'aac',
  'ac3',
  'act',
  'adf',
  'adts',
  'adx',
  'aea',
  'afc',
  'aiff',
  'alaw',
  'alsa',
  'amr',
  'anm',
  'apc',
  'ape',
  'aqtitle',
  'asf',
  'ast',
  'au',
  'avi',
  'avm2',
  'avr',
  'avs',
  'bfi',
  'bink',
  'bit',
  'bmv',
  'boa',
  'brstm',
  'c93',
  'caf',
  'cdg',
  'cdxl',
  'daud',
  'dfa',
  'dirac',
  'divx',
  'dnxhd',
  'dsicin',
  'dts',
  'dtshd',
  'dvd',
  'dxa',
  'ea',
  'ea_cdata',
  'eac3',
  'epaf',
  'f32be',
  'f32le',
  'f4v',
  'film_cpk',
  'filmstrip',
  'fli',
  'flic',
  'flc',
  'flv',
  'frm',
  'g722',
  'g723_1',
  'g729',
  'gxf',
  'h261',
  'h263',
  'h264',
  'hds',
  'hevc',
  'hls',
  'hls',
  'idf',
  'iff',
  'ismv',
  'iss',
  'iv8',
  'ivf',
  'jv',
  'latm',
  'lavfi',
  'lmlm4',
  'loas',
  'lvf',
  'lxf',
  'm4v',
  'mgsts',
  'microdvd',
  'mjpeg',
  'mkv',
  'mlp',
  'mm',
  'mmf',
  'mov',
  'mov',
  'mp4',
  'm4a',
  '3gp',
  '3g2',
  'mj2',
  'mp2',
  'mp4',
  'mpeg',
  'mpegts',
  'mpg',
  'mpjpeg',
  'mpl2',
  'mpsub',
  'mtv',
  'mv',
  'mvi',
  'mxf',
  'mxg',
  'nsv',
  'null',
  'nut',
  'nuv',
  'ogg',
  'ogv',
  'oma',
  'opus',
  'oss',
  'paf',
  'pjs',
  'pmp',
  'psp',
  'psxstr',
  'pva',
  'pvf',
  'qcp',
  'r3d',
  'rl2',
  'rm',
  'roq',
  'rpl',
  'rsd',
  'rso',
  'rtp',
  'rtsp',
  's16be',
  's16le',
  's24be',
  's24le',
  's32be',
  's32le',
  's8',
  'sami',
  'sap',
  'sbg',
  'sdl',
  'sdp',
  'sdr2',
  'segment',
  'shn',
  'siff',
  'smjpeg',
  'smk',
  'smush',
  'sol',
  'sox',
  'svcd',
  'swf',
  'tak',
  'tee',
  'thp',
  'tmv',
  'truehd',
  'vc1',
  'vcd',
  'v4l2',
  'vivo',
  'vmd',
  'vob',
  'voc',
  'vplayer',
  'vqf',
  'w64',
  'wc3movie',
  'webm',
  'webvtt',
  'wmv',
  'wsaud',
  'wsvqa',
  'wtv',
  'wv',
  'xa',
  'xbin',
  'xmv',
  'xwma',
  'yop',
];
export const imageExtensions = [
  'aai',
  'art',
  'arw',
  'avs',
  'bpg',
  'bmp',
  'bmp2',
  'bmp3',
  'cals',
  'cgm',
  'cin',
  'cip',
  'cmyk',
  'cr2',
  'crw',
  'cube',
  'cur',
  'cut',
  'cut',
  'dcm',
  'dcr',
  'dcx',
  'dds',
  'dib',
  'djvu',
  'dng',
  'dpx',
  'emf',
  'epdf',
  'epi',
  'eps',
  'eps2',
  'eps3',
  'epsf',
  'epsi',
  'ept',
  'exr',
  'fax',
  'fig',
  'fits',
  'fpx',
  'gif',
  'gplt',
  'gray',
  'graya',
  'hdr',
  'heic',
  'hpgl',
  'hrz',
  'ico',
  'info',
  'jbig',
  'jng',
  'jp2',
  'jpt',
  'j2c',
  'j2k',
  'jpg',
  'jpeg',
  'jxr',
  'jxl',
  'mat',
  'miff',
  'mono',
  'mng',
  'm2v',
  'mpc',
  'mpr',
  'mrw',
  'msl',
  'mtv',
  'mvg',
  'nef',
  'orf',
  'otb',
  'p7',
  'palm',
  'pam',
  'pbm',
  'pcd',
  'pcds',
  'pcl',
  'pcx',
  'pdb',
  'pef',
  'pes',
  'pfa',
  'pfb',
  'pfm',
  'pgm',
  'picon',
  'pict',
  'pix',
  'png',
  'png8',
  'png00',
  'png24',
  'png32',
  'png48',
  'png64',
  'pnm',
  'ppm',
  'psd',
  'ptif',
  'pwp',
  'rad',
  'raf',
  'rgb',
  'rgb565',
  'rgba',
  'rgf',
  'rla',
  'rle',
  'sct',
  'sfw',
  'sgi',
  'sun',
  'svg',
  'tga',
  'tiff',
  'tif',
  'ttf',
  'ubrl',
  'ubrl6',
  'uil',
  'viff',
  'wbmp',
  'wbmp',
  'wdp',
  'wmf',
  'wpg',
  'x',
  'xbm',
  'xcf',
  'xwd',
  'x3f',
  'yuv',
  'xpm',
];

export const threeDExtensions = ['stl', 'obj', 'fbx', 'dae', 'gltf', 'glb'];
